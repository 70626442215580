/* Static Page CSS */
/*.lincoln-contact {
    padding:10px;
}*/

/********Shipping and Delivery******/

.content-section .page__content h1 {
  color: #324047 !important;
  font-family: var(--fmc-font--miller) !important;
  font-weight: 400;
}

.content-section .page__content p {
  font-weight: 400;
  color: #324047 !important;
  letter-spacing: 1.5px;
  font-size: 17px;
  font-family: var(--fmc-font--proxima-nova);
}

.content-section .page__content span {
  font-family: var(--fmc-font--proxima-nova);
  color: #324047;
}

@media (max-width: 999px) {
  .content-section .page__content h1 {
    font-size: 24px;
  }

  .content-section .page__content p {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
}
/********Faq******/

.faq-topic-headings__heading {
  color: #324047 !important;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 10px;
}

.faq-qa__items {
  padding-left: 0 !important;
}

.faq-qa__item {
  margin-bottom: 0 !important;
}

.faq-qa__items .accordion-item .accordion-header {
  background-color: #fff;
}

.faq-qa__question {
  color: #324047 !important;
  // font-stretch: expanded;
  font-family: var(--fmc-font--proxima-nova) !important;
}

.faq-qa__item.open .faq-qa__question {
  color: #324047;
}

.accordion .accordion-item.open > .accordion-content {
  margin-bottom: 40px !important;
}

.answer p {
  padding: 0;
  color: #324047;
  font-family: var(--fmc-font--proxima-nova);
}

.faq-qa .open .accordion-content {
  max-height: initial;
}

.faq-intro__content p {
  color: #324047 !important;
  font-family: var(--fmc-font--proxima-nova) !important;
}
.faq-intro__content p a {
  color: #324047;
  font-weight: 400;
}

.faq-topics__topic.active .faq-topics__topic-link {
  border-bottom: 3px solid #324047;
  padding: 15px;
}
@media (max-width: 1024px) {
  .faq-topics__topic.active .faq-topics__topic-link {
    border-bottom: 0px;
    font-weight: 900;
    color: #324047 !important;
  }
}

.faq-topics__topic-link {
  cursor: pointer;
  color: #324047 !important;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-weight: 400;
}

.faq-contact__title {
  color: #324047 !important;
  font-family: var(--fmc-font--proxima-nova);
}

.faq-contact__content {
  background: #f4f4f4 !important;
  padding: 20px 30px;
}
.faq-contact__content p {
  color: #324047 !important;
  font-family: var(--fmc-font--miller);
}
.faq-contact__content p a {
  color: #324047;
}

.faq-search-form__input {
  color: #324047;
  font-family: var(--fmc-font--proxima-nova) !important;
}
.faq-qa__answer a {
  color: #324047 !important;
  text-decoration: underline !important;
  font-family: var(--fmc-font--proxima-nova) !important;
}

.page-hero__heading {
  color: #ffffff;
}

.contact-box__inner-content:last-child {
  margin-bottom: 40px;
}
.contact-box__inner.contact-box__content.contact-box__inner-content {
  font-family: var(--fmc-font--miller) !important;
  width: 100%;
  color: #4d4d4d;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500 !important;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
.contact-box .contact-box__heading.title--sm {
  font-size: 26px !important;
  font-weight: 500 !important;
}
// .contact-box__content-footer:hover .contact-box__footer-link-text {
//   color: #f26148;
// }
.contact-boxes,
.container--faq {
  a:hover {
    color: #f26148 !important;
  }
}

.return-details,
.return-details-items {
  margin-top: 60px;
  margin-bottom: 60px;
  .return-details__form-actions-cta.btn:after {
    position: relative;
    right: -1px;
  }
  .return-details__form-actions-contact a:hover {
    color: #f26148;
  }
  .return-details__title {
    font-weight: 400;
    font-family: var(--fmc-font--proxima-nova) !important;
  }
  p a {
    text-decoration: underline;
  }
  .return-details__form,
  p {
    font-stretch: normal;
    font-weight: 400;
    font-size: 17px;
  }
  div.order__details-text p:hover {
    color: #f26148;
  }
  .return-details-items__content {
    .return-details-items__content-product {
      .return-details-items__content-product-info {
        position: relative;
        .MuiIconButton-label {
          svg {
            position: absolute;
            font-size: 24px;
          }
          input[disabled] {
            opacity: 0;
          }
        }
        .order__details {
          width: 100%;
          a {
            display: flex;
            flex-direction: row;
            @media (max-width: 768px) {
              display: initial;
            }
            img {
              border: none;
              margin-right: 20px;
            }
            .order__details-text {
              .order__product-title {
                margin-bottom: 10px;
              }
            }
          }
          .disableTag {
            pointer-events: none;
            opacity: 0.7;
          }
        }
        .return-details__form-error {
          color: #6e6e6e;
          background: #e3e3e3;
          position: absolute;
          top: 4rem;
          left: 13.5rem;
          width: 75%;
          height: auto;
          line-height: normal;
          padding: 3px 10px 10px;
          border: none;
          box-shadow: 0 2px 4px rgba(100, 100, 100, 0.4019607);
          & > .warn.warning {
            top: 0.25em !important;
            height: 1em !important;
            &::before {
              border-bottom-color: #6e6e6e;
            }
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .return-details-items__info {
    .return-details-items__info-form {
      .return-details-items__info-form-actions {
        select {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .state-popup {
    .return-modal-content-container {
      .order__details {
        width: 80%;
        a {
          display: flex;
          flex-direction: row;
          img {
            margin-right: 20px;
          }
        }
        a:hover {
          color: #f26148 !important;
        }
      }
    }
  }
}
.return-details.is-hidden {
  display: none;
}

.return-details__title {
  margin-bottom: 1em;
  font-size: 24px;
}

@media (min-width: 481px) {
  .return-details__title {
    font-size: 34px;
  }
}

.return-details__description {
  width: 45%;
  margin: 0 auto 2em;
}

@media (max-width: 767px) {
  .return-details__description {
    width: 95%;
  }
}

.return-details__form .input-field {
  width: 50%;
  margin: 0 auto 2em;
  label {
    font-weight: 500;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .return-details__form .input-field {
    width: 95%;
  }
}

.return-details__form .input-field .return-details__form-error {
  width: 100%;
}

.return-details__form-error {
  width: 100%;
  height: 5em;
  line-height:24px;
  margin: 0 auto 2em;
  border: #d62d0a 0px solid;
  border-top-color: #d62d0a;
  border-top-width: 1em;
  -moz-box-shadow: 0px 3px 8px rgba(100, 100, 100, 0.5);
  -webkit-box-shadow: 0px 3px 8px rgba(100, 100, 100, 0.5);
  box-shadow: 0 3px 8px #64646480;
}

@media (min-width: 767px) {
  .return-details__form-error {
    width: 50%;
  }
}

.return-details__form-error > .warn {
  font-size: 24px;
  color: transparent;
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details__form-error > .warn:before {
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details__form-error > .warn:after {
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details__form-error > .warn.warning {
  display: inline-block;
  top: 0.5em;
  width: 1.65em;
  height: 1.25em;
  border: none;
  background-color: transparent;
}

.return-details__form-error > .warn.warning:before {
  content: "";
  display: block;
  top: -0.08em;
  left: 0.25em;
  position: absolute;
  border: transparent 0.6em solid;
  border-bottom-color: #d62d0a;
  border-bottom-width: 1em;
  border-top-width: 0;
}

.return-details__form-error > .warn.warning:after {
  display: block;
  position: absolute;
  top: 0.3em;
  left: 0.05em;
  width: 100%;
  text-align: center;
  content: "!";
  font-size: 0.5em;
  color: #fff;
}

.return-details__form-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 2em;
}

.return-details__form-actions-cta {
  width: fit-content;
  margin-bottom: 3em;
}

@media (max-width: 480px) {
  .return-details__form-actions-cta {
    width: fit-content;
  }
}

@media (max-width: 480px) {
  .return-details__form-actions-support {
    text-align: center;
  }
}

.return-details__form-actions-contact {
  margin-left: 0.5em;
}

@media (max-width: 480px) {
  .return-details__form-actions-contact {
    text-align: center;
    margin-top: 1em;
  }
}

@media (min-width: 481px) {
  .return-details__form-actions > div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.return-details-items hr {
  border: 0;
  height: 0;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.return-details-items.is-hidden {
  display: none;
}

.return-details-items .is-disabled {
  filter: opacity(0.7);
}

.return-details-items__title {
  font-size: 30px;
  margin-bottom: 2em;
}

@media (max-width: 480px) {
  .return-details-items__title {
    margin-bottom: 1em;
  }
}

.return-details-items__subheading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
}

.return-details-items__subheading-description {
  width: 70%;
}

@media (max-width: 480px) {
  .return-details-items__subheading-description {
    margin-bottom: 1em;
  }
}

.return-details-items__subheading-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .return-details-items__subheading-price {
    display: none;
  }
}

.return-details-items__subheading-total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .return-details-items__subheading-total {
    display: none;
  }
}

.return-details-items__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 480px) {
  .return-details-items__info {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.return-details-items__info-customer {
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  margin-right: 1.5em;
}

@media (max-width: 480px) {
  .return-details-items__info-customer {
    margin: 0;
  }
}

.return-details-items__info-customer-address {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 2px;
}

.return-details-items__info-customer-details {
  font-size: 14px;
}

@media (max-width: 480px) {
  .return-details-items__info-customer-details {
    font-size: 12px;
  }
}

.return-details-items__info-customer-support {
  margin-top: 1em;
  width: 90%;
}

.return-details-items__info-form {
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  border: 0;
  width: 100%;
}

@media (min-width: 481px) {
  .return-details-items__info-form {
    width: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.return-details-items__info-form div[data-select-product-warn] {
  width: 100%;
  background: #cc452a;
  padding: 20px;
  color: #fff;
  box-shadow: 0 10px 10px #0000001a, 0 20px 20px #0000001a;
  line-height: 18px;
  margin-top: 1.5em;
}

.return-details-items__info-form-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1.5em;
  margin-left: 1.5em;
}

@media (max-width: 480px) {
  .return-details-items__info-form-actions {
    margin-left: 0;
  }
}

.return-details-items__info-form-actions hr {
  display: none;
  border: 0;
  height: 0;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 480px) {
  .return-details-items__info-form-actions hr {
    display: block;
  }
}

.return-details-items__info-form-actions button {
  width: 40%;
  margin-left: auto;
}
.return-details-items__info-form-actions button.btn-blue-hover:after,
.return-modal-content-actions button.btn-blue-hover:after {
  position: relative;
  right: 2px;
}

@media (max-width: 480px) {
  .return-details-items__info-form-actions button {
    width: fit-content;
    margin: 0 auto;
  }
}

.return-details-items__info-form-actions ul {
  list-style: none;
}

.return-details-items__info-form-actions ul li p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border: 1px solid gray;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 1em;
  margin-top: 1em;
}

.return-details-items__info-form-actions ul li p:after {
  content: "";
  background-image: url(https://accessories.lincoln.com/gcs/B2C/lincoln/BackGround_Images/icon-chevron-down-primary.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 17px;
  height: 8px;
}

.return-details-items__info-form-actions ul li p.is-hidden {
  margin-bottom: 1em;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.return-details-items__info-form-actions-dropdown {
  list-style: none;
  width: 100%;
  border: 1px solid gray;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  padding: 0.5em 0;
  margin-bottom: 1em;
  max-height: 200px;
  overflow-y: scroll;
}

.return-details-items__info-form-actions-dropdown::-webkit-scrollbar {
  width: 25px;
}

.return-details-items__info-form-actions-dropdown::-webkit-scrollbar-track {
  background: #e0e0e0;
  margin: 1em 0;
  border-right: 20px white solid;
  background-clip: padding-box;
}

.return-details-items__info-form-actions-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-right: 20px white solid;
  background-clip: padding-box;
}

.return-details-items__info-form-actions-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.return-details-items__info-form-actions-dropdown.is-hidden {
  display: none;
}

.return-details-items__info-form-actions-dropdown li:first-of-type {
  display: none;
}

.return-details-items__info-form-actions-dropdown li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 0.5em 1em;
}

@media (max-width: 480px) {
  .return-details-items__info-form-actions-dropdown li {
    font-size: 12px;
  }
}

.return-details-items__info-form-actions-dropdown li input {
  margin: 0 1em 0 0;
}

.return-details-items__content-product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 1em 0;
  @media (max-width: 768px) {
    display: initial;
  }
}

.return-details-items__content-product-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 70%;
}

@media (max-width: 480px) {
  .return-details-items__content-product-info {
    width: 100%;
  }
}

.return-details-items__content-product-info-checkbox {
  margin-right: 1em !important;
}

@media (max-width: 480px) {
  .return-details-items__content-product-info-checkbox {
    width: 25px !important;
  }
}

.return-details-items__content-product-info-image {
  display: block;
  width: 175px;
  max-height: 120px;
  overflow: hidden;
}

@media (max-width: 480px) {
  .return-details-items__content-product-info-image {
    width: 160px;
    max-height: 80px;
  }
}

.return-details-items__content-product-info-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.return-details-items__content-product-info-title {
  width: 80%;
  margin-left: 1em;
}

@media (min-width: 481px) {
  .return-details-items__content-product-info-title.is-disabled {
    margin-bottom: 3em;
  }
}

.return-details-items__content-product-info-title-total {
  display: none;
}

@media (max-width: 480px) {
  .return-details-items__content-product-info-title-total {
    display: block;
  }
}

.return-details-items__content-product-info-title-disabled {
  width: fit-content;
  font-size: 12px;
  background-color: #e6e6e6;
  padding: 0.3em;
  margin-top: -4em;
  margin-bottom: 1em;
}

@media (max-width: 480px) {
  .return-details-items__content-product-info-title-disabled {
    display: none;
  }
}

.return-details-items__content-product-info-title-disabled a {
  text-decoration: none;
  font-weight: 700;
  padding-right: 0.4em;
  color: #102b4e;
}

.return-details-items__content-product-info-title-disabled > span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

@media (max-width: 480px) {
  .return-details-items__content-product-info-title-disabled > span {
    display: none;
  }
}

.return-details-items__content-product-info-title-disabled .warn {
  font-size: 24px;
  color: transparent;
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details-items__content-product-info-title-disabled .warn:before {
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details-items__content-product-info-title-disabled .warn:after {
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details-items__content-product-info-title-disabled .warn.warning {
  display: inline-block;
  top: 0.25em;
  width: 1.25em;
  height: 1.05em;
  border: none;
  background-color: transparent;
  margin-bottom: 0.25em;
}

.return-details-items__content-product-info-title-disabled
  .warn.warning:before {
  content: "";
  display: block;
  left: 3px;
  position: absolute;
  border: transparent 0.5em solid;
  border-bottom-color: #3f3f3f;
  border-bottom-width: 0.85em;
  border-top-width: 0;
}

.return-details-items__content-product-info-title-disabled .warn.warning:after {
  display: block;
  position: absolute;
  top: 0.3em;
  left: 0.05em;
  width: 100%;
  text-align: center;
  content: "!";
  font-size: 0.5em;
  color: #fff;
}

.return-details-items__content-product-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (max-width: 480px) {
  .return-details-items__content-product-price {
    display: none;
  }
}

.return-details-items__content-product-total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (max-width: 480px) {
  .return-details-items__content-product-total {
    display: none;
  }
}

.return-details-items__content-disabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: fit-content;
  font-size: 12px;
  background-color: #e6e6e6;
  padding: 0.3em;
  margin-top: 1em;
}

@media (min-width: 481px) {
  .return-details-items__content-disabled {
    display: none;
  }
}

.return-details-items__content-disabled a {
  text-decoration: none;
  font-weight: 700;
  padding-right: 0.4em;
  color: #102b4e;
}

.return-details-items__content-disabled p {
  margin-left: 0.5em;
}

.return-details-items__content-disabled div .warn {
  font-size: 24px;
  color: transparent;
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details-items__content-disabled div .warn:before {
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details-items__content-disabled div .warn:after {
  position: relative;
  padding: 0;
  margin: 0;
}

.return-details-items__content-disabled div .warn.warning {
  display: inline-block;
  top: 0.25em;
  width: 1.25em;
  height: 1.05em;
  border: none;
  background-color: transparent;
  margin-bottom: 0.25em;
}

.return-details-items__content-disabled div .warn.warning:before {
  content: "";
  display: block;
  left: 3px;
  position: absolute;
  border: transparent 0.5em solid;
  border-bottom-color: #3f3f3f;
  border-bottom-width: 0.85em;
  border-top-width: 0;
}

.return-details-items__content-disabled div .warn.warning:after {
  display: block;
  position: absolute;
  top: 0.3em;
  left: 0.05em;
  width: 100%;
  text-align: center;
  content: "!";
  font-size: 0.5em;
  color: #fff;
}

.return-details-items__price div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 1em 0;
}

.return-details-items .return-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000;
  background-color: #0006;
}

.return-details-items .return-modal.is-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.return-details-items .return-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px 30px;
  border: 1px solid #888;
  width: 50%;
  height: fit-content;
  top: 57%;
}

@media (max-width: 480px) {
  .return-details-items .return-modal-content {
    width: 90%;
    padding: 0 10px;
  }
}

.return-details-items .return-modal-content hr {
  border: 0;
  height: 0;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.return-details-items .return-modal-content-container {
  width: 100%;
}

.return-details-items .return-modal-content-container__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 1em 0;
  column-gap: 1em;
}

.return-details-items .return-modal-content-container__item-image {
  display: block;
  width: 25%;
  max-height: min-content;
  overflow: hidden;
}

@media (max-width: 480px) {
  .return-details-items .return-modal-content-container__item-image {
    width: 125px;
    max-height: 80px;
  }
}

.return-details-items .return-modal-content-container__item-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.return-details-items .return-modal-content-container__item-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  column-gap: 0.5em;
}

@media (max-width: 480px) {
  .return-details-items .return-modal-content-container__item-text {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.return-details-items .return-modal-content-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: 700;
}

.return-details-items .return-modal-content-close:hover,
.return-details-items .return-modal-content-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.return-details-items .return-modal-content-header {
  margin: 1em 0;
}

.return-details-items .return-modal-content-header-title {
  margin-bottom: 1em;
  font-weight: 400;
  font-size: 22px;
}

.return-details-items .return-modal-content-header-subtitle {
  margin-bottom: 1em;
  font-size: 15px;
}

.return-details-items .return-modal-content-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-justify-content: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 1.5em 0;
}

@media (max-width: 480px) {
  .return-details-items .return-modal-content-actions-close {
    display: none;
  }
}

.page__content h1.buyreward-info {
  font-size: 40px;
  line-height: normal;
  @media (max-width: 1024px) {
    font-size: 24px;
  }
}
.signin-rewards:hover {
  @media (max-width: 1024px) {
    color: #f26148;
  }
}
.signin-rewards:after {
  position: relative !important;
  right: -2px !important;
}
.california-title {
  @media (max-width: 768px) {
    font-size: 20px !important;
  }
}
.ContentPage {
  .page-hero__textarea p { 
    color: #fff !important;
  }
}
